import Snackbar from "@material-ui/core/Snackbar";
import { useState } from "react";
import Alert from '@material-ui/lab/Alert';
const Alerta = ({isOpen, sms}) => {
  const [open, setOpen] = useState(isOpen);
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={sms}
      >
        <Alert variant="filled" severity="error">
       {sms}
      </Alert>
      </Snackbar>
    </>
  );
};

export default Alerta;
