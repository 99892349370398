import styled from "styled-components";

const Container = styled.div`
  margin: 0px 60px;
  background: #ffffff;
  border-radius: 13px;
  border-radius: 20px;
  padding: 10px 20px;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    margin: 0px 10px;
  }
`;

const DadosAluno = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;

const Bloco = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Aluno = styled.div`
  width: 100%;
  @media (max-width: 760px) {
    width: auto;
  }
`;

const Dados = styled.div`
  padding: 10px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
`;

export { Container, DadosAluno, Bloco, Dados, Aluno, ContainerButton };
