import React, { useEffect, useState } from "react";
import {
  SelectCadastro,
  InputCadastro,
  InputMask,
} from "../../../../components/elements/InputCadastro/InputCadastro";
import { makeStyles } from "@material-ui/core/styles";
import * as S from "./AlunoFormStyled";
import {
  apiServices,
  getTurma,
  postValidarAluno,
  completarEndereco,
} from "../../service/cadastroAluno.service";
import { apiUser } from '../../../../services/base.service'
import { Button } from "@material-ui/core";
import { getFormStore, setFormStore } from "../../../../context/FormStore";
import Alerta from "../../../../components/modules/Alerta/Alerta";

const useStyles = makeStyles((theme) => ({
  smallInput: {
    width: "25%",
  },
  regularInput: {
    width: "50%",
    marginBottom: "10px",
  },
  largeInput: {
    width: "100%",
    marginBottom: "10px",
  },
}));

function AlunoForm({ nextCallback, backCallback }) {
  const dados = getFormStore();
  const classes = useStyles();
  const [escola, setEscola] = useState([]);
  const [estado, setEstado] = useState([]);
  const [anoEscolar, setAnoEscolar] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [municipioResponsavel, setMunicipioResponsavel] = useState([]);
  const [turma, setTurma] = useState([]);
  const [menorDeIdade, setMenorDeIdade] = useState(dados.menorIdade);
  const [error, setError] = useState(false);
  const [matriculaValida, setMatriculaValida] = useState(true);
  const [open, setOpen] = useState(false);
  const [bairro, setBairro] = useState(dados.alunoEnderecoBairro || "");
  const [logradouro, setLogradouro] = useState(dados.alunoEnderecoLogradouro || '');
  const [estadoSelecionado, setEstadoSelecionado] = useState("");
  const [cidadeSelecionado, setCidadeSelecionado] = useState(dados.alunoEnderecoCidade || "");
  const [turmaSelecionada, setTurmaSelecionada] = useState(dados.turmaId || "");
  const [bairroResponsavel, setBairroResponsavel] = useState(dados.responsavelEnderecoBairro || "");
  const [logradouroResponsavel, setLogradouroResponsavel] = useState(dados.responsavelEnderecoLogradouro || "");
  const [estadoResponsavel, setEstadoResponsavel] = useState(dados.responsavelEnderecoEstado || "");
  const [cidadeResponsavel, setCidadeResponsavel] = useState(dados.responsavelEnderecoCidade || "");
  const sexo = [
    { label: "MASCULINO", value: 1 },
    { label: "FEMININO", value: 2 },
    { label: "OUTROS", value: 3 },
  ];

  const campoAluno = [
    [
      [
        {
          required: true,
          inputType: "default",
          className: classes.largeInput,
          name: "alunoMatricula",
          label: "Matrícula",
          onBlur: (event) => validarMatricula(event.target.value),
          defaultValue: dados.alunoMatricula,
        },
      ],
      [
        {
          required: true,
          inputType: "default",
          className: classes.regularInput,
          name: "alunoNome",
          label: "Nome",
          defaultValue: dados.alunoNome,
        },
        {
          required: true,
          inputType: "default",
          className: classes.regularInput,
          name: "alunoSobreNome",
          label: "Sobrenome",
          defaultValue: dados.alunoSobreNome,
        },
      ],
      [
        {
          required: false,
          inputType: "mask",
          className: classes.smallInput,
          name: "alunoEnderecoCep",
          label: "CEP",
          mask: "00000-000",
          defaultValue: dados.alunoEnderecoCep,
          onBlur: (event) => preencherEndereco(event.target.value),
        },
        {
          required: true,
          inputType: "default",
          className: classes.largeInput,
          name: "alunoEnderecoLogradouro",
          label: "Endereço",
          onChange: (e) => handleChangeAluno(e.target.value, "logradouro"),
          value: logradouro,
        },
      ],
      [
        {
          required: true,
          inputType: "select",
          className: classes.largeInput,
          name: "escolaId",
          label: "Escola",
          select: true,
          options: escola,
          defaultValue: dados.escolaId,
          onChange: (event) => onEscolaChange(event),
        },
      ],
    ],
    [
      [
        {
          required: false,
          inputType: "mask",
          className: classes.largeInput,
          name: "alunoCpf",
          label: "CPF",
          mask: "000.000.000-00",
          defaultValue: dados.alunoCpf,
        },
      ],
      [
        {
          required: true,
          inputType: "select",
          className: classes.regularInput,
          name: "alunoSexo",
          label: "Sexo",
          select: true,
          options: sexo,
          defaultValue: dados.alunoSexo,
        },
        {
          required: true,
          inputType: "default",
          className: classes.largeInput,
          name: "alunoEmail",
          type: "email",
          label: "E-mail",
          defaultValue: dados.alunoEmail,
        },
      ],
      [
        {
          required: true,
          inputType: "mask",
          className: classes.smallInput,
          name: "alunoEnderecoNumero",
          label: "Nº",
          mask: Number,
          defaultValue: dados.alunoEnderecoNumero,
        },
        {
          required: false,
          inputType: "default",
          className: classes.regularInput,
          name: "alunoEnderecoComplemento",
          label: "Complemento",
          defaultValue: dados.alunoEnderecoComplemento,
        },
        {
          required: true,
          inputType: "default",
          className: classes.regularInput,
          name: "alunoEnderecoBairro",
          label: "Bairro",
          onChange: (e) => handleChangeAluno(e.target.value, "bairro"),
          value: bairro,
        },
      ],
      [
        {
          required: true,
          inputType: "select",
          className: classes.largeInput,
          name: "serieId",
          label: "Ano escolar",
          select: true,
          options: anoEscolar,
          defaultValue: dados.serieId,
        },
      ],
    ],
    [
      [
        {
          required: true,
          inputType: "mask",
          className: classes.largeInput,
          name: "alunoDataNascimento",
          label: "Data de nascimento",
          mask: "00/00/0000",
          onBlur: (event) => alunoMenorDeIdade(event),
          defaultValue: dados.alunoDataNascimento,
        },
      ],
      [
        {
          required: false,
          inputType: "mask",
          className: classes.largeInput,
          name: "alunoTelefone",
          label: "Celular",
          mask: "(00) 00000-0000",
          defaultValue: dados.alunoTelefone,
        },
      ],
      [
        {
          required: true,
          inputType: "select",
          className: classes.largeInput,
          name: "alunoEnderecoEstado",
          label: "Estado",
          select: true,
          options: estado,
          onChange: (event) => obterMunicipio(event.target.value),
          value: estadoSelecionado,
        },
        {
          required: true,
          inputType: "select",
          className: classes.regularInput,
          name: "alunoEnderecoCidade",
          label: "Cidade",
          select: true,
          options: municipio,
          onChange: (e) => handleChangeAluno(e.target.value, "cidade"),
          value: cidadeSelecionado,
        },
      ],
      [
        {
          required: true,
          inputType: "select",
          className: classes.largeInput,
          name: "turmaId",
          label: "Turma/ Turno",
          select: true,
          options: turma,
          onChange: (e) => handleChangeAluno(e.target.value,'turma'),
          value: turmaSelecionada,
        },
      ],
    ],
  ];

  const campoResponsavel = [
    [
      [
        {
          required: true,
          inputType: "mask",
          className: classes.largeInput,
          name: "responsavelCpf",
          label: "CPF",
          mask: "000.000.000-00",
          defaultValue: dados.responsavelCpf,
        },
      ],
      [
        {
          required: true,
          inputType: "default",
          type: "email",
          className: classes.largeInput,
          name: "responsavelEmail",
          label: "E-mail",
          defaultValue: dados.responsavelEmail,
        },
      ],
      [
        {
          required: false,
          inputType: "default",
          className: classes.largeInput,
          name: "responsavelEnderecoLogradouro",
          label: "Endereço",
          onChange: (e) =>
            handleChangeResponsavel(e.target.value, "logradouro"),
          value: logradouroResponsavel,
        },
      ],
    ],
    [
      [
        {
          required: true,
          inputType: "default",
          className: classes.largeInput,
          name: "responsavelNome",
          label: "Nome",
          defaultValue: dados.responsavelNome,
        },
        {
          required: true,
          inputType: "default",
          className: classes.largeInput,
          name: "responsavelSobreNome",
          label: "Sobrenome",
          defaultValue: dados.responsavelSobreNome,
        },
      ],
      [
        {
          required: false,
          inputType: "mask",
          className: classes.largeInput,
          name: "responsavelTelefone",
          label: "Celular",
          mask: "(00) 00000-0000",
          defaultValue: dados.responsavelTelefone,
        },
      ],
      [
        {
          required: false,
          inputType: "mask",
          className: classes.smallInput,
          name: "responsavelEnderecoNumero",
          label: "Nº",
          mask: Number,
          defaultValue: dados.responsavelEnderecoNumero,
        },
        {
          required: false,
          inputType: "default",
          className: classes.regularInput,
          name: "responsavelEnderecoComplemento",
          label: "Complemento",
          defaultValue: dados.responsavelEnderecoComplemento,
        },
        {
          required: true,
          inputType: "default",
          className: classes.regularInput,
          name: "responsavelEnderecoBairro",
          label: "Bairro",
          defaultValue: dados.responsavelEnderecoBairro,
          onChange: (e) => handleChangeResponsavel(e.target.value, "bairro"),
          value: bairroResponsavel,
        },
      ],
    ],
    [
      [
        {
          required: true,
          inputType: "mask",
          className: classes.largeInput,
          name: "responsavelDataNascimento",
          label: "Data de nascimento",
          mask: "00/00/0000",
          defaultValue: dados.responsavelDataNascimento,
        },
        {
          required: true,
          inputType: "default",
          className: classes.largeInput,
          name: "responsavelGrauParentesco",
          label: "Grau de parentesco",
          defaultValue: dados.responsavelGrauParentesco,
        },
      ],
      [
        {
          required: false,
          inputType: "mask",
          className: classes.largeInput,
          name: "responsavelEnderecoCep",
          label: "CEP",
          mask: "00000-000",
          defaultValue: dados.responsavelEnderecoCep,
          onBlur: (event) =>
            preencherEndereco(event.target.value, "responsavel"),
        },
      ],
      [
        {
          required: true,
          inputType: "select",
          className: classes.largeInput,
          name: "responsavelEnderecoEstado",
          label: "Estado",
          select: true,
          options: estado,
          onChange: (event) => obterMunicipioResponsavel(event.target.value),
          defaultValue: dados.responsavelEnderecoEstado,
          value: estadoResponsavel,
        },
        {
          required: true,
          inputType: "select",
          className: classes.regularInput,
          name: "responsavelEnderecoCidade",
          label: "Cidade",
          select: true,
          options: municipioResponsavel,
          onChange: (e) => handleChangeResponsavel(e.target.value, "cidade"),
          value: cidadeResponsavel,
        },
      ],
    ],
  ];

  useEffect(() => {
    obterSelect();
    alunoMenorDeIdade();
  }, []);

  function obterSelect() {
    obterEstados();
    obterEscolas();
    obterSerie();
    if (dados.alunoEnderecoEstado) {
      obterMunicipio(dados.alunoEnderecoEstado);
    }

    if (dados.responsavelEnderecoEstado) {
      obterMunicipioResponsavel(dados.responsavelEnderecoEstado);
    }

    if (dados.escolaId) {
      ObterTurma(dados);
    }

    if (dados.alunoMatricula) {
      validarMatricula(dados.alunoMatricula);
    }
  }

  function handleSubmit(data) {
    validarCampo(data);
  }

  function handleChangeAluno(valorCampo, nomeCampo) {
    if (nomeCampo === "logradouro") {
      setLogradouro(valorCampo);
    } else if (nomeCampo === "bairro") {
      setBairro(valorCampo);
    } else if (nomeCampo === "cidade") {
      setCidadeSelecionado(valorCampo);
    }else if(nomeCampo === 'turma'){
      setTurmaSelecionada(valorCampo)
    }
  }

  function handleChangeResponsavel(valorCampo, nomeCampo) {
    if (nomeCampo === "logradouro") {
      setLogradouroResponsavel(valorCampo);
    } else if (nomeCampo === "bairro") {
      setBairroResponsavel(valorCampo);
    } else if (nomeCampo === "cidade") {
      setCidadeResponsavel(valorCampo);
    }
  }

  function obterEscolas() {
    apiUser
      .get('Escola')
      .then((response) =>
        setEscola(
          response.data.map((e) => {
            return {
              label: e.nomeFantasia,
              value: e.id,
              idEncripty: e.idEncripty,
            };
          })
        )
      )
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function obterEstados() {
    apiServices
      .get("estado")
      .then((response) =>
        setEstado(
          response.data.data.map((e) => {
            return { label: e.nome, value: e.sigla };
          })
        )
      )
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function obterSerie() {
    apiUser
      .get('serie')
      .then((response) => {
        setAnoEscolar(
          response.data.map((e) => {
            return { label: e.Descricao, value: e.id };
          })
        );
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function obterMunicipio(estado) {
    setEstadoSelecionado(estado);
    apiServices
      .get(`municipio/${estado}`)
      .then((response) => {
        setMunicipio(
          response.data.data.map((e) => {
            return { label: e.nome, value: e.nome };
          })
        );
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function obterMunicipioResponsavel(estado) {
    setEstadoResponsavel(estado);
    apiServices
      .get(`municipio/${estado}`)
      .then((response) => {
        setMunicipioResponsavel(
          response.data.data.map((e) => {
            return { label: e.nome, value: e.nome };
          })
        );
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function onEscolaChange(event) {
    if (!event) return;
    let escolaSelecionada = escola.find((e) => e.value === event.target.value);
    ObterTurma(escolaSelecionada);
  }

  function ObterTurma(escolaSelecionada) {
    getTurma
      .get(
        `Turma/GetTurmasEscolaId?EscolaId=${escolaSelecionada.idEncripty}&ano=2023`
      )
      .then((response) => {
        setTurma(
          response.data.map((e) => {
            return {
              label: `${e.Descricao} / ${e.TurnoDaTurma}`,
              turma: `${e.Descricao}`,
              value: e.TurmaGuid,
              TurnoDaTurma: e.TurnoDaTurma,
            };
          })
        );
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function validarCampo(data) {
    let turmaSelecionada = turma.find((e) => e.value === data.turmaId);
    let escolaSelecionada = escola.find((e) => e.value === data.escolaId);
    let anoEscolarSelecionado = anoEscolar.find(
      (e) => e.value === data.serieId
    );
    setOpen(false);
    postValidarAluno
      .post("precadastro/validar-dados-aluno", {
        ...data,
        menorIdade: menorDeIdade,
        EscolaNome: escolaSelecionada.label,
        SerieDescricao: anoEscolarSelecionado.label,
        TurmaDescricao: turmaSelecionada.turma,
        Turno: turmaSelecionada.TurnoDaTurma,
      })
      .then((response) => {
        setFormStore({
          ...data,
          menorIdade: menorDeIdade,
          EscolaNome: escolaSelecionada.label,
          SerieDescricao: anoEscolarSelecionado.label,
          TurmaDescricao: turmaSelecionada.turma,
          Turno: turmaSelecionada.TurnoDaTurma,
          idEncripty: escolaSelecionada.idEncripty,
        });
        nextCallback();
      })
      .catch((err) => {
        if (err) {
          console.error("ops! ocorreu um erro" + err);
          setOpen(true);
          setError(err.response.data.errors[0]);
        }
      });
  }

  function alunoMenorDeIdade(event) {
    setOpen(false);
    if (event === undefined) return;
    let dataDeNascimento = { dataNascimento: event.target.value };
    postValidarAluno
      .post("precadastro/aluno-menor-idade", dataDeNascimento)
      .then((response) => {
        setMenorDeIdade(response.data.data);
      })
      .catch((err) => {
        setOpen(true);
        setError(err.response.data.errors);
      });
  }

  function validarMatricula(event) {
    setOpen(false);
    if (event === undefined) return;
    let matriculaValida = { matricula: event };
    postValidarAluno
      .post("precadastro/validar-matricula-aluno", matriculaValida)
      .then((response) => {
        setMatriculaValida(response.data.data);
      })
      .catch((err) => {
        setMatriculaValida(true);
        setOpen(true);
        setError(err.response.data.errors);
      });
  }

  function preencherEndereco(cep, responsavel) {
    completarEndereco
      .get(`ws/${cep}/json/`)
      .then((response) => {
        if (responsavel === "responsavel") {
          obterMunicipioResponsavel(response.data.uf);
          setBairroResponsavel(response.data.bairro);
          setLogradouroResponsavel(response.data.logradouro);
          setEstadoResponsavel(response.data.uf.toUpperCase());
          if (municipioResponsavel) {
            setCidadeResponsavel(response.data.localidade.toUpperCase());
          }
        } else {
          obterMunicipio(response.data.uf);
          setBairro(response.data.bairro);
          setLogradouro(response.data.logradouro);
          setEstadoSelecionado(response.data.uf.toUpperCase());
          if (municipio) {
            setCidadeSelecionado(response.data.localidade.toUpperCase());
          }
        }
      })
      .catch((err) => {});
  }

  function Formulario(campos) {
    function DecidirInput({ inputType, options, ...props }) {
      switch (inputType) {
        case "select":
          if (options === undefined) return <SelectCadastro {...props} />;

          return (
            <SelectCadastro {...props}>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectCadastro>
          );

        case "mask":
          return <InputMask {...props} />;

        default:
          return <InputCadastro {...props} />;
      }
    }
    return (
      <S.Formulario>
        {campos.map((blocos) => (
          <S.Bloco>
            {blocos.map((grids, index) => (
              <S.Grid key={index}>{grids.map(DecidirInput)}</S.Grid>
            ))}
          </S.Bloco>
        ))}
      </S.Formulario>
    );
  }

  return (
    <S.Container>
      <S.ContainerAviso>
        {open ? <Alerta isOpen={true} sms={error}></Alerta> : null}
        <S.Aviso>
          Atenção os campos com <S.Red>*</S.Red> são obrigatórios
        </S.Aviso>
      </S.ContainerAviso>

      <S.ContainerAluno onSubmit={handleSubmit}>
        {!dados.alunoMatricula ? (
          <div>
            <S.Perfil>Dados do Aluno</S.Perfil>
            {Formulario(campoAluno)}
            {menorDeIdade ? (
              <div>
                <S.Perfil>Dados do Responsável</S.Perfil>
                {Formulario(campoResponsavel)}
              </div>
            ) : null}
          </div>
        ) : null}

        {
        dados.alunoMatricula &&
        estado.length &&
        anoEscolar.length &&
        escola.length &&
        municipio.length ? 
        (
          <div>
            <S.Perfil>Dados do Aluno</S.Perfil>
            {Formulario(campoAluno)}
            {menorDeIdade ? (
              <div>
                <S.Perfil>Dados do Responsável</S.Perfil>
                {Formulario(campoResponsavel)}
              </div>
            ) : null}
          </div>
        ) : null}

        <S.ContainerButton>
          <Button
            type="submit"
            disabled={matriculaValida}
            Button
            variant="contained"
            color="primary"
          >
            Próximo
          </Button>
        </S.ContainerButton>
      </S.ContainerAluno>
    </S.Container>
  );
}

export default AlunoForm;
