import * as S from "./AnexarDocumentoStyled";
import React from "react";
import CardArquivo from "../../components/modules/CardArquivo/CardArquivo";
import InputFile from "../../components/elements/InputFile/InputFile";
import { update } from "../CadastroAluno/service/cadastroAluno.service";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { getFormStore, setFormStore } from "../../context/FormStore";
import Alerta from "../../components/modules/Alerta/Alerta";

const AnexarDocumento = ({ nextCallback, backCallback }) => {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);

  function pastaArquivo(file, pasta) {
    const formData = new FormData();
    formData.append("arquivo", file);
    formData.append(pasta, "");
    formData.append("conectedu", "");
    return formData;
  }

  function anexarArquivo(files) {
    if (files != null) {
      setOpen(false);
      let formData = pastaArquivo(files[0], "Arquivo");
      update
        .post("ConectEdu/UploadConectEdu", formData)
        .then((response) => {
          setFormStore({
            ...getFormStore(),
            arquivo: response.data.nomeArquivo,
          });
          nextCallback();
        })
        .catch((err) => {
          setOpen(true);
          console.error("ops! ocorreu um erro" + err);
        });
    }
  }

  function deletarArquivo(index) {
    files.splice(index, 1);
    setFiles([]);
  }

  return (
    <S.Container>
      {open ? (
        <Alerta isOpen={true} sms={"Selecione um arquivo PDF, PNG ou JPEG"}></Alerta>
      ) : null}
      <S.Titulo>Escolha um documento de identificação</S.Titulo>
      <S.TipoArquivo>
        <p>Certidão de nascimento</p>
        <p>Identidade</p>
        <p>CPF</p>
      </S.TipoArquivo>
      <InputFile onFileSelected={setFiles} />
      {files[0] ? (
        <CardArquivo
          nome={files[0].path}
          tamanho={files[0].size}
          files={files}
          onClick={() => deletarArquivo()}
        />
      ) : null}
      <S.ContainerButton>
        <Button
          onClick={() => backCallback()}
          Button
          variant="contained"
          style={{ marginRight: "10px", color: "#fff", background: "#8B8B8B" }}
        >
          Voltar
        </Button>
        <Button
          onClick={() => anexarArquivo(files)}
          Button
          variant="contained"
          color="primary"
        >
          Próximo
        </Button>
      </S.ContainerButton>
    </S.Container>
  );
};

export default AnexarDocumento;
