import axios from "axios";

// const getEscolas = axios.create({
//   baseURL: "https://api.hub.conectedu.com/api/v2/Escola",
// }); 

// const getSerie = axios.create({
//   baseURL: "https://api.hub.conectedu.com/api/v2/serie",
// });

const apiServices = axios.create({
  baseURL: "https://api.services.techdog.com.br/api/",
});

const getTurma = axios.create({
  baseURL: `https://api.hub.conectedu.com/api/`,
});

const postValidarAluno = axios.create({
  baseURL: "https://api.conectedu.com/usuarios/api/",
});

const update = axios.create({
  baseURL: "https://conectedustorageapi.azurewebsites.net/api/",
});

const completarEndereco = axios.create({
  baseURL: "https://viacep.com.br/",
});


export {apiServices, getTurma, postValidarAluno, update, completarEndereco};
