import styled from "styled-components";

const Container = styled.header`
  display: flex;
  background: #5e74f8;
`;

const Menu = styled.header`
  background: #5e74f8;
  height: 100vh;
  padding: 0px 10px;
  // border-radius: 0 25% 0 10%;
`;

const ContainerLogo = styled.div`
`
 
const Logo = styled.img`
  width: 120px;
  padding: 40px;
`;

const Cadastro = styled.div`
background: #E5E5E5;
width: 100vw;
border-radius: 50px 0 0 50px;
@media (max-width: 720px) {
  // height: 100vh;
  border-radius: 0px;
}
`;



export { Container, Menu, ContainerLogo, Logo, Cadastro };
