import styled from "styled-components";

const Menu = styled.header`
  background: #5e74f8;
  height: 100vh;
  @media (max-width: 720px) {
    display: none;
  }
`;

const ContainerLogo = styled.div``;

const Logo = styled.img`
  width: 120px;
  padding: 40px;
  @media (max-width: 720px) {
    display: none;
  }
`;

const Button = styled.button`
  background: rgba(249, 249, 249, 0.2);
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 150px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
`;

const Link = styled.a`
  color:#fff;
`;
export { Menu, ContainerLogo, Logo, Button, Link };
