import { useDropzone } from "react-dropzone";
import React from 'react';
import { useEffect } from "react";
import * as S from "./InputFileStyled";
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';

function InputFile({ onFileSelected }) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({accept: {'image/*': ['.png','.jpeg', '.pdf']}})

  useEffect(() => {
    onFileSelected(acceptedFiles)
  },[acceptedFiles])



  return (
        <S.Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <InsertDriveFileSharpIcon fontSize="large" style={{ color: '5E74F8' }}/>
          <input {...getInputProps()}/>
          <S.Texto>
            Selecione ou arraste e solte seu arquivo pdf, png ou jpeg a partir
            do seu computador
          </S.Texto>
        </S.Container>
  );
};

export default InputFile;
