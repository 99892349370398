import styled from "styled-components";

const Container = styled.div`
  //   display: flex;
  //   justify-content: flex-end;
  padding: 20px;
  min-width: 380px;
  max-width: 430px;
`;

const BoxText = styled.div`
  margin-bottom: 25px;
`;

export { Container, BoxText };
