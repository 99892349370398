import React from "react";
import * as S from "./CadastrarAlunoStyled";
import MenuProgressivo from "../../components/elements/Stepper/Stepper";
import AlunoForm from "./components/AlunoForm/AlunoForm";
import Menu from "../../components/modules/Menu/Menu";
import AnexarDocumento from "../AnexarDocumento/AnexarDocumento";
import DetalhesInscricao from "../DetalhesInscricao/DetalhesInscricao";

function CadastroAluno() {  
  return (
    <S.Container>
      <Menu></Menu>
      <S.Cadastro>
        <MenuProgressivo titulo={['Cadastro do aluno', 'Inserir documentos', 'Resumo da inscrição']} steps={[
          AlunoForm,
          AnexarDocumento,
           DetalhesInscricao,
           ]}></MenuProgressivo>
      </S.Cadastro>
    </S.Container>
  );
}

export default CadastroAluno;
