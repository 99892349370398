import styled from "styled-components";

const Container = styled.div`
// width:300px;
padding: 40px;
background: #fff;
border-radius: 5px;
`;

const Titulo = styled.h1`
font-size: 24px;
color: #010101;
text-align: center;
margin-bottom: 30px;
`;

const Perfil = styled.div`
display: flex;
justify-content: space-between;
gap:15px;
`


export { Container, Titulo, Perfil };
