import styled from "styled-components";
import { Form } from "@unform/web";

const Container = styled.section`
  margin: 0px 60px;
  border-radius: 20px;
  background: #fff;
  padding: 20px 10px;
  @media (max-width: 720px) {
    margin: 0px 10px;
  }
`;

const ContainerAviso = styled.div`
  height: 58px;
  background: #e3f8ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Perfil = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

const Aviso = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin-left: 20px;
  @media (max-width: 720px) {
    font-size: 18px;
  }
`;

const ContainerAluno = styled(Form)`
  padding: 0 20px;
`;

const Bloco = styled.div`
  flex: 1;
`;

const Formulario = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
  @media (max-width: 1050px) {
    display: block;
  }
`;

const Grid = styled.div`
  display: flex;
  gap: 12px;
`;

const Red = styled.strong`
  color: red;
`;
const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
`;

export {
  Container,
  ContainerAviso,
  Aviso,
  Formulario,
  Bloco,
  Grid,
  ContainerAluno,
  Perfil,
  Red,
  ContainerButton,
};
