import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 25px;
  border: 1px dashed #5e74f8;
  border-style: dashed;
  background-color: #e7eaff;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 20px;
`;

const Icon = styled.img`
width: 100px;
`

const Texto = styled.p`
color: #000;
text-align: center;
` 

export { Container, Icon, Texto };
