import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import * as S from "./StepperStyled";
import Comfirmacao from "../../../pages/Comfirmacao/Comfirmacao";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    borderRadius: "20px",
  },
}));

function getSteps() {
  return ["Passo", "Passo", "Passo"];
}

export default function MenuProgressivo(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(stepIndex, steps) {
    switch (stepIndex) {
      case 0:
        let Pag1 = steps[0];
        return <Pag1 nextCallback={handleNext} backCallback={handleBack} />;
      case 1:
        let Pag2 = steps[1];
        return <Pag2 nextCallback={handleNext} backCallback={handleBack} />;
      case 2:
        let Pag3 = steps[2];
        return <Pag3 nextCallback={handleNext} backCallback={handleBack} />;
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <div className={classes.root}>
      {activeStep != steps.length ? (
        <>
          <S.Container>
            <S.Titulo>{props.titulo[activeStep]}</S.Titulo>
            <S.ContainerButton>
              <a href="/">
              <Button size="small" Button variant="contained" color="primary">
                Sair
              </Button>
              </a>
            </S.ContainerButton>
          </S.Container>
          <S.ContainerStepper>
            <Stepper
              className={classes.stepper}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </S.ContainerStepper>
        </>
      ) : null}
      <div>
        {activeStep === steps.length ? (
          <div>
            <Comfirmacao />
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, props.steps)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
