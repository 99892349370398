import React, {useState} from "react";
import * as S from "./DetalhesInscricaoStyled";
import { getFormStore } from "../../context/FormStore";
import { Button } from "@material-ui/core";
import {
  postValidarAluno
} from "../../pages/CadastroAluno/service/cadastroAluno.service";

const DetalhesInscricao = ({ nextCallback, backCallback }) => {
  const [adicionarAluno, setAdicinarAluno] = useState();
  const dadosFormulario = getFormStore();
  console.log("dadosFormulario", dadosFormulario);
  
  const dadosAluno = [
    [
      [
        {
          name: "Matrícula",
          value: dadosFormulario.alunoMatricula,
        },
      ],
      [
        {
          name: "Nome",
          value: dadosFormulario.alunoNome,
        },
        {
          name: "Sobrenome",
          value: dadosFormulario.alunoSobreNome,
        },
      ],
      [
        {
          name: "CEP",
          value: dadosFormulario.alunoEnderecoCep,
        },
        {
          name: "Endereço",
          value: dadosFormulario.alunoEnderecoLogradouro,
        },
      ],
      [
        {
          name: "Escola",
          value: dadosFormulario.EscolaNome,
        },
      ],
    ],

    [
      [
        {
          name: "CPF",
          value: dadosFormulario.alunoCpf,
        },
      ],
      [
        {
          name: "Sexo",
          value: dadosFormulario.alunoSexo == '1' ? 'Masculino' : dadosFormulario.alunoSexo === '2' ? 'Feminino' : 'Outros',
        },
        {
          name: "E-mail",
          value: dadosFormulario.alunoEmail,
        },
      ],
      [
        {
          name: "Nº",
          value: dadosFormulario.alunoEnderecoNumero,
        },
        {
          name: "Complemento",
          value: dadosFormulario.alunoEnderecoComplemento,
        },
        {
          name: "Bairro",
          value: dadosFormulario.alunoEnderecoBairro,
        },
      ],
      [
        {
          name: "Ano escolar",
          value: dadosFormulario.SerieDescricao,
        },
      ],
    ],
    [
      [
        {
          name: "Data de nascimento",
          value: dadosFormulario.alunoDataNascimento,
        },
      ],
      [
        {
          name: "Celular",
          value: dadosFormulario.alunoTelefone,
        },
      ],
      [
        {
          name: "Estado",
          value: dadosFormulario.alunoEnderecoEstado,
        },
        {
          name: "Cidade",
          value: dadosFormulario.alunoEnderecoCidade,
        },
      ],
      [
        {
          name: "Turma/ Turno",
          value: `${dadosFormulario.TurmaDescricao}/${dadosFormulario.Turno}`,
        },
      ],
    ],
  ];

  const dadosResponsavel = [
    [
      [
        {
          name: "CPF",
          value: dadosFormulario.responsavelCpf,
        },
      ],
      [
        {
          name: "E-mail",
          value: dadosFormulario.responsavelEmail,
        },
      ],
      [
        {
          name: "Endereço",
          value: dadosFormulario.responsavelEnderecoLogradouro,
        },
      ],
    ],
    [
      [
        {
          name: "Nome",
          value: dadosFormulario.responsavelNome,
        },
        {
          name: "Sobrenome",
          value: dadosFormulario.responsavelSobreNome,
        },
      ],
      [
        {
          name: "Celular",
          value: dadosFormulario.responsavelTelefone,
        },
      ],
      [
        {
          name: "Nº",
          value: dadosFormulario.responsavelEnderecoNumero,
        },
        {
          name: "Complemento",
          value: dadosFormulario.responsavelEnderecoComplemento,
        },
        {
          name: "Bairro",
          value: dadosFormulario.responsavelEnderecoBairro,
        },
      ],
    ],
    [
      [
        {
          name: "Data de nascimento",
          value: dadosFormulario.responsavelDataNascimento,
        },
        {
          name: "Grau de parentesco",
          value: dadosFormulario.responsavelGrauParentesco,
        },
      ],
      [
        {
          name: "CEP",
          value: dadosFormulario.responsavelEnderecoCep,
        },
      ],
      [
        {
          name: "Estado",
          value: dadosFormulario.responsavelEnderecoEstado,
        },
        {
          name: "Cidade",
          value: dadosFormulario.responsavelEnderecoCidade,
        },
      ],
    ],
  ];

  function aluno(dadosAluno) {
    return (
      <>
        {dadosAluno.map((blocos) => (
          <S.Aluno>
            {blocos.map((campos) => (
              <S.Bloco>
                {campos.map((campo) => (
                  <S.Dados>
                    <strong>{campo.name}</strong>
                    <p>{campo.value}</p>
                  </S.Dados>
                ))}
              </S.Bloco>
            ))}
          </S.Aluno>
        ))}
      </>
    );
  }

  function responsavel(dadosResponsavel) {
    return (
      <>
        {dadosResponsavel.map((blocos) => (
          <S.Aluno>
            {blocos.map((campos) => (
              <S.Bloco>
                {campos.map((campo) => (
                  <S.Dados>
                    <strong>{campo.name}</strong>
                    <p>{campo.value}</p>
                  </S.Dados>
                ))}
              </S.Bloco>
            ))}
          </S.Aluno>
        ))}
      </>
    );
  }

  function cadastrarAluno() {
   postValidarAluno
      .post("precadastro/adicionar-aluno", dadosFormulario)
      .then((response) => {
        setAdicinarAluno(response.data.data);
        nextCallback()
      })
      .catch((err) => {
      });
  }

  return (
    <S.Container>
      <h2>Dados do Aluno</h2>
      <S.DadosAluno>{aluno(dadosAluno)}</S.DadosAluno>

      {dadosFormulario.menorIdade ? (
        <>
          <h2>Dados do Responsável</h2>
          <S.DadosAluno>{responsavel(dadosResponsavel)}</S.DadosAluno>
        </>
      ) : null}
      <S.ContainerButton>
      <Button
          onClick={() => backCallback()}
          Button
          variant="contained"
          style={{ marginRight: "10px", color: "#fff", background: "#8B8B8B" }}
        >
          Voltar
        </Button>
        <Button
          onClick={() => cadastrarAluno()}
          Button
          variant="contained"
          color="primary"
        >
          Próximo
        </Button>
      </S.ContainerButton>
    </S.Container>
  );
};

export default DetalhesInscricao;
