import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "../../elements/Button";
import CardPerfil from "./components/CardPerfil/CardPerfil";
import * as S from "./ModalStyled";


function ModalProfessorAluno() {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button type="button"  color='#5E74F8' onClick={handleOpen}>
      Pertenço a rede, e desejo me cadastrar
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition 
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <S.Container>
          <S.Titulo>Escolha seu perfil</S.Titulo>
          <S.Perfil>
            <CardPerfil texto='Sou aluno(a)' src='https://imgur.com/MPajhna.png' background={'#5e74f8'}></CardPerfil>
            {/* <CardPerfil texto='Sou professor(a)' src='https://imgur.com/RlyWdJX.png' background={'#ced655'}></CardPerfil> */}
          </S.Perfil>
          </S.Container>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalProfessorAluno;