import React from "react";
import * as S from "./CardPerfilStyled";

function CardPerfil({ texto, src, background }) {
  return (
    <>
      <a href="/cadastroAluno">
        <S.Card>
          <S.ContainerFoto>
            <S.Foto src={src} background={background} />
          </S.ContainerFoto>
          <S.ContainerButton>
            <S.Button>{texto}</S.Button>
          </S.ContainerButton>
        </S.Card>
      </a>
    </>
  );
}

export default CardPerfil;
