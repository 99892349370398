import styled from "styled-components";

const ContainerLoading = styled.div`
box-sizing: border-box;
background: #E7EAFF;
border: 1px dashed #5E74F8;
border-radius: 25px;
display: flex;
align-items: center;
padding: 20px;
margin-bottom: 20px;
position: relative;
}
`;

const Icon = styled.div`
  margin-right: 10px;
`;

const Content = styled.div`
  width: 100%;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
`;

const Nome = styled.p`
word-break: break-word;
margin-right: 10px;
`

export { ContainerLoading, Content, Icon, Close, Nome };
