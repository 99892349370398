import React from "react";
import * as S from "./CardArquivoStyled";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

const CardArquivo = ({ nome, tamanho, files, onClick}) => {
  return (
    <>
    {
      files ?
      <S.ContainerLoading>
      <S.Close>
        <CloseSharpIcon onClick={onClick} style={{ color: 'ff0000', cursor: 'pointer' }}/>
      </S.Close>
      <S.Icon>
        <InsertDriveFileOutlinedIcon fontSize="large" />
      </S.Icon>
      <S.Content>
        <div>
          <S.Nome>{nome}</S.Nome>
          <p>{tamanho} bytes</p>
        </div>
      </S.Content>
    </S.ContainerLoading>
    : null
    }
    </>
  );
};

export default CardArquivo;
